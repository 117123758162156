









































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default defineComponent({
  name: 'v-selectgood',
  components: {
    VSelect,
  },
  props: {
    label: String,
    id: String,
    value: {
      type: String,
      required: false,
    },
    errors: String,
    options: Array,
    placeholder: String,
    disable: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    dynamicOrientation: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const select: Ref = ref<any | null>(props.value || null)

    const onInput = (value: any) => {
      ctx.emit('input', value)
    }

    watch(
      () => props.options,
      _ => {
        if (props.options?.length === 0) {
          select.value = null
        }
      }
    )

    watch(
      () => props.value,
      _ => {
        select.value = props.value
      }
    )

    const calculatedPosition = (dropdownList, component, { width }) => {
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    }

    return {
      select,
      onInput,
      calculatedPosition,
    }
  },
})
