


























import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

export const codeMask = {
  '+32': '### ## ## ##',
  '+33': '# ## ## ## ##',
  '+352': '### ### ###',
}

export default defineComponent({
  name: 'select-phone-flag',
  props: {
    formName: {
      type: String,
      required: false,
      default: '',
    },
    selectedPhoneCode: {
      type: String,
      required: false,
      default: '+32',
    },
  },

  setup(props, ctx) {
    const chrome = useState(['chrome'])
    const phoneFallBack = {
      picture: {
        url: '',
      },
      phoneCode: props.selectedPhoneCode,
      phoneNumberFormat: [
        {
          phoneNumber: '499123456',
        },
      ],
    }

    const phoneAvailableCountries = chrome.chrome.value.data
      .phoneAvailableCountries?.label?.items
      ? chrome.chrome.value.data.phoneAvailableCountries?.label?.items
      : [phoneFallBack]

    const phoneCountryRank = ref('0')
    const phoneCountryFlag = ref('')
    const phoneCountryRegex = ref('|(4)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')

    if (props.selectedPhoneCode) {
      phoneCountryRank.value = phoneAvailableCountries.findIndex(
        item => item.phoneCode === props.selectedPhoneCode
      )

      phonePlaceHolder.value =
        phoneAvailableCountries[
          phoneCountryRank.value
        ].phoneNumberFormat[0].phoneNumber

      phoneCountryRegex.value = ''

      phoneAvailableCountries[phoneCountryRank.value].phoneNumberFormat.forEach(
        (format: { phoneNumber: string }) => {
          phoneCountryRegex.value += `|(${format.phoneNumber.charAt(0)})[0-9]{${
            format.phoneNumber.length - 1
          }}`
        }
      )

      ctx.root.$emit(`phone:flag:updated:${props.formName}`, {
        phoneCode: props.selectedPhoneCode,
        placeHolder: phonePlaceHolder.value,
        regex: phoneCountryRegex.value,
      })
    } else {
      ctx.root.$emit(`phone:flag:updated:${props.formName}`, {
        phoneCode: phoneAvailableCountries[phoneCountryRank.value].phoneCode,
        placeHolder: phonePlaceHolder.value,
        regex: phoneCountryRegex.value,
      })
    }

    phoneCountryFlag.value = phoneAvailableCountries[phoneCountryRank.value]
      .picture.url
      ? process.env.VUE_APP_API_DOMAIN +
        phoneAvailableCountries[phoneCountryRank.value].picture.url
      : ''

    const phoneCountryChange = e => {
      phoneCountryRank.value = e.target.value
      phoneCountryRegex.value = ''

      phonePlaceHolder.value =
        phoneAvailableCountries[
          phoneCountryRank.value
        ].phoneNumberFormat[0].phoneNumber

      phoneCountryFlag.value =
        process.env.VUE_APP_API_DOMAIN +
        phoneAvailableCountries[phoneCountryRank.value].picture.url

      phoneAvailableCountries[phoneCountryRank.value].phoneNumberFormat.forEach(
        (format: { phoneNumber: string }) => {
          phoneCountryRegex.value += `|(${format.phoneNumber.charAt(0)})[0-9]{${
            format.phoneNumber.length - 1
          }}`
        }
      )
      ctx.root.$emit(`phone:flag:updated:${props.formName}`, {
        phoneCode: phoneAvailableCountries[phoneCountryRank.value].phoneCode,
        placeHolder: phonePlaceHolder.value,
        regex: phoneCountryRegex.value,
      })
    }

    return {
      phoneAvailableCountries,
      phoneCountryFlag,
      phoneCountryChange,
    }
  },
})
